import React, {Component} from 'react';

export class BlankView extends Component {
  componentDidMount() {

  }

  render() {

    return (
      <div>
      </div>
    );
  }
}
